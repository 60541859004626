import moment from "moment";
import { FilterModes, OR_FILTER } from "../constants";

const dateTypes = {
  day: "MM/DD/YYYY",
  dayTime: "MM/DD/YYYY HH:mm",
  time: "HH:mm",
};

const masks = {
  day: "99/99/9999",
  dayTime: "99/99/9999 99:99",
  time: "99:99",
};

export function getMask(dateType = "dayTime") {
  return masks[dateType];
}

export function formatDate(date, dateType = "dayTime") {
  return moment.tz(date, "Africa/Casablanca").format(dateTypes[dateType]);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function lowerFirst(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getEnumOption(option) {
  if (typeof option === "object") {
    return option;
  }
  return { value: option, title: option };
}

export function objToQuery(object) {
  return new URLSearchParams(object).toString();
}

export function isValidJsonFilter(filter) {
  if (filter?.isEmpty) {
    return true;
  }
  if (!filter?.mode) {
    return false;
  }
  if (filter.value) {
    return true;
  }
  return filter.minValue && filter.maxValue;
}

export const booleanValues = {
  true: "Yes",
  false: "No",
  1: "Yes",
  0: "No",
};

export const getValueToShow = ({
  value,
  type,
  dateType,
  showValue,
  raw,
  placeholder,
}) => {
  if (type === "date" && value) {
    return formatDate(value, dateType);
  }
  if (type === "boolean" && !showValue) {
    return booleanValues[value];
  }
  if (showValue) {
    return showValue(value);
  }
  if (type === "number" && !raw) {
    return numberWithCommas(value || "");
  }
  return value || placeholder;
};

const formatOrFilterValue = (value) => {
  let jsonFilter = value;
  try {
    jsonFilter = JSON.parse(jsonFilter || "{}");
  } catch (_err) {}
  if (Array.isArray(jsonFilter) && jsonFilter.length === 1) {
    jsonFilter = jsonFilter[0];
  }
  return jsonFilter;
};

export function formatFamilyFilters({
  pageSize = 300,
  page = 0,
  distributionId,
  distributionEventId,
  campaignId,
  doordashModel,
  ...filter
}) {
  const requestParams = {};
  const andFilters = [];
  const orFilters = [];

  const sortEntries = ([key, value]) => {
    switch (key) {
      case "searchTerm": {
        requestParams.searchTerm = value;
        break;
      }
      case "excludedDistributionIds": {
        requestParams.excludedDistributionIds = value ? JSON.parse(value) : [];
        break;
      }
      case "excludedEventIds": {
        requestParams.excludedEventIds = value ? JSON.parse(value) : [];
        break;
      }
      case "sortOptions":
        const orderTermValue = value.type === 1 ? "desc" : "asc";
        requestParams.OrderByProperty = value.property;
        requestParams.OrderTerm = orderTermValue;
        break;
      default:
        const filterSetting = {
          PropertyName: key,
          FilterValue: String(value),
          FilterType: FilterModes.Contains,
          BtweenValue1:
            value.maxValue || value.maxValue === 0 ? String(value.minValue) : 0,
          BtweenValue2:
            value.maxValue || value.maxValue === 0 ? String(value.maxValue) : 0,
          BetweenDate1: null,
          BetweenDate2: null,
        };

        if (Array.isArray(value) && value[0]?.type === OR_FILTER) {
          orFilters.push(
            ...value.map((i) => ({
              ...filterSetting,
              FilterValue: formatOrFilterValue(i.value ?? i),
            }))
          );
          break;
        }
        if (typeof value === "object") {
          if (value.type === OR_FILTER) {
            orFilters.push({
              ...filterSetting,
              FilterValue: formatOrFilterValue(value.value),
            });
            break;
          }
          filterSetting.FilterValue = value.value ? String(value.value) : "";
          filterSetting.FilterType = Number(value.mode);
        }

        andFilters.push(filterSetting);
        break;
    }
  };
  Object.entries(filter).forEach(sortEntries);

  requestParams.AndFilters = andFilters;
  requestParams.OrFilters = orFilters;
  return {
    PageSize: pageSize,
    Page: +page,
    distributionId,
    distributionEventId,
    campaignId,
    doordashModel,
    ...requestParams,
  };
}

export function downloadFile(data, fileName) {
  const file = new Blob([data]);
  const url = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}
