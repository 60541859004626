import axios from "../utils/axios";

export function getDistributions() {
  return axios.get("/Distributions/GetDistributions");
}

export function GetDistributionsTree() {
  return axios.get("/Distributions/GetDistributionsTree");
}

export function getDistributionEvents(distributionId) {
  return axios.get(`/Distributions/GetDistributionEvents/${distributionId}`);
}

export function createDistribution(location, name) {
  return axios.get(
    `/Distributions/CreateDistribution/${location}/${encodeURIComponent(name)}`
  );
}

export function updateDistribution(id, location, name) {
  return axios.get(
    `/Distributions/UpdateDistribution/${id}/${location}/${encodeURIComponent(
      name
    )}`
  );
}

/**
 * @param {{
 *  distributionId: number;
 *  createMessageQueue: boolean;
 *  messageId: number;
 *  eventName: string;
 *  file: File;
 *  notes: string;
 * }} data
 */
export function createEventFromDoordashFile(data) {
  const formData = new FormData();

  formData.append("distributionId", data.distributionId);
  formData.append("createMessageQueue", data.createMessageQueue);
  formData.append("messageId", data.messageId);
  formData.append("eventName", data.eventName);
  formData.append("file", data.file);
  formData.append("notes", data.notes);

  return axios.post("/Distributions/CreateEventFromDoordashFile", formData);
}

/**
 * @param {{
 *  id: number;
 *  name: string;
 *  notes: string;
 *  distributionId: number;
 * }} data
 */
export function updateDistributionEvent(data) {
  return axios.post("/Distributions/UpdateDistributionEvent", data);
}

/**
 * @param {number} eventId
 * @param {number} messageId
 */
export function sendMessagesForDeliveryEvent(eventId, messageId) {
  return axios.post(
    `/Distributions/SendMessagesForDeliveryEvent/${eventId}/${messageId}`
  );
}

export function attachFamiliesToDistribution(distributionId, familyIds) {
  return axios.post(
    `/Distributions/AttachFamiliesToDistribution/${distributionId}`,
    familyIds
  );
}

// TODO
export function unmapFamiliesFromDistribution(distributionId) {
  return axios.post(
    `/Distributions/unmapFamiliesFromDistribution/${distributionId}`
  );
}

export function attachFamiliesToDistributionWithFile(distributionId, file) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `/Distributions/AttachFamiliesToDistributionWithFile/${distributionId}`,
    formData
  );
}

export function createDistributionEvent(distributionId, name) {
  return axios.post("/Distributions/CreateDistributionEvent", {
    distributionId,
    name,
  });
}

// TODO
export function attachFamiliesToDistributionEvent(distributionId) {
  return axios.post(
    `/Distributions/AttachFamiliesToDistributionEvent/${distributionId}`
  );
}

export function deleteDistribution(id) {
  return axios.post(`/Distributions/DeleteDistribution/${id}`);
}

export function deleteDistributionEvent(id) {
  return axios.post(`/Distributions/DeleteDistributionEvent/${id}`);
}
