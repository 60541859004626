import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Form, FormCheck } from "react-bootstrap";
import { UtilityContext } from "../../../contexts/UtilityContext";
import { DistributionContext } from "../../../contexts/DistributionContext";
import { getDistributionEvents } from "../../../services/distributions";
import { forEach } from "lodash";

const MoreFilters = ({
  onFilter,
  filters,
  onFilterClear,
  searchTerm,
  onSearchTermChange,
  onSearch,
}) => {
  const { dropdownValues } = useContext(UtilityContext);
  const { distributions } = useContext(DistributionContext);
  const [ expandedDistributionIds, setExpandedDistributionIds ] = useState([]);
  const [ events, setEvents ] = useState([]);
  const [ selectedDistributionIds, setSelectedDistributionIds ] = useState([]);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {

    if (!isMounted) {
      setIsMounted(true);
      return;
    }

    const alreadySelectedEventIds = events.filter(ev => selectedDistributionIds.includes(ev.distributionId)).map(ev => ev.id);

    onFilter({
      excludedDistributionIds: JSON.stringify(selectedDistributionIds),
      excludedEventIds: JSON.stringify(selectedEventIds.filter(id => !alreadySelectedEventIds.includes(id)))
    })

  }, [selectedDistributionIds, selectedEventIds])

  const activeOnlyChanged = (e) => {
    if (e.target.checked) {
      onFilter({ active: 1 });
    } else {
      onFilterClear("active");
    }
  };

  const excludeExceptionsChanged = (e) => {
    if (e.target.checked) {
      onFilter({ exception: "[-1]" });
    } else {
      onFilterClear("exception");
    }
  };

  return (
    <div className="d-flex align-items-center flex-wrap gap-2">
      <div className="d-flex">
        <Form.Control
          value={searchTerm}
          placeholder="Search term"
          style={{ width: 200, borderRadius: 5, marginRight: 4 }}
          onChange={(e) => onSearchTermChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.code.indexOf("Enter") > -1) {
              onSearch();
            }
          }}
        />
        <Dropdown className="me-2 nav-item" align="end">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {dropdownValues.masbiahLocationIdMap?.[
              JSON.parse(filters.masbiahLocationId || "[]")[0]
            ] || "Masbia Location"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {dropdownValues.masbiahLocationId?.map((item) => (
              <Dropdown.Item
                key={item.value}
                onClick={() =>
                  onFilter({ masbiahLocationId: JSON.stringify([item.value]) })
                }
                active={
                  JSON.parse(filters.masbiahLocationId || "[]")[0] ===
                  item.value
                }
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="me-2 nav-item" align="end">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            Excluded Distributions
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div style={{
              maxHeight: '300px',
              overflowY: 'auto',
              padding: '0 5px'
            }}>

              <div className="mb-2 d-flex justify-content-center">
                <button className="btn btn-sm btn-light" disabled={selectedDistributionIds.length === 0 && selectedEventIds.length === 0} onClick={() => {
                  setSelectedEventIds([])
                  setSelectedDistributionIds([])
                }}>Clear Selection</button>
              </div>

              {distributions.map(d =>
                <div key={d.id}>
                  <div className="d-flex gap-1 align-items-center">
                    <a href onClick={(e) => {
                      e.preventDefault();
                      if (expandedDistributionIds.includes(d.id)) {
                        setExpandedDistributionIds(expandedDistributionIds.filter(id => id !== d.id))
                      }
                      else {
                        getDistributionEvents(d.id).then((data) => {
                          setEvents([...events.filter(ev => ev.distributionId !== d.id), ...data])

                          setExpandedDistributionIds([...expandedDistributionIds, d.id])
                          if (selectedDistributionIds.includes(d.id)) {
                            setSelectedEventIds([...new Set([...selectedEventIds, ...data.map(d => d.id)])]);
                          }
                        });
                      }
                    }}>
                      {!expandedDistributionIds.includes(d.id) ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                        : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-minus"><path d="M5 12h14" /></svg>
                      }
                    </a>
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" checked={selectedDistributionIds.includes(d.id)} onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedDistributionIds([...selectedDistributionIds, d.id])
                          const distributionEventIds = events.filter(ev => ev.distributionId === d.id).map(ev => ev.id);
                          setSelectedEventIds([...new Set([...selectedEventIds, ...distributionEventIds])]);
                        }
                        else {
                          setSelectedDistributionIds(selectedDistributionIds.filter(id => id !== d.id))
                          const distributionEventIds = events.filter(ev => ev.distributionId === d.id).map(ev => ev.id);
                          setSelectedEventIds(selectedEventIds.filter(id => !distributionEventIds.includes(id)))
                        }
                      }} />
                    </div>
                    <div style={{
                      fontWeight: '500',
                      fontSize: '14px'
                    }}>{d.name}</div>
                  </div>
                  {expandedDistributionIds.includes(d.id) &&
                    <div style={{ marginLeft: '40px' }}>
                      {events.filter(e => e.distributionId === d.id).length > 0 ?
                        <div>
                          {events.filter(e => e.distributionId === d.id).map(event =>
                            <div key={event.id} className="d-flex gap-1 align-items-center">
                              <div className="form-check">
                                <input type="checkbox" className="form-check-input" checked={selectedEventIds.includes(event.id)} onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedEventIds([...selectedEventIds, event.id])
                                    const distributionEventIds = events.filter(ev => ev.distributionId === d.id).map(ev => ev.id);
                                    const allSelected = distributionEventIds.every(id => selectedEventIds.includes(id) || id === event.id);
                                    if (allSelected) {
                                      setSelectedDistributionIds([...selectedDistributionIds, d.id])
                                    }
                                  }
                                  else {
                                    setSelectedEventIds(selectedEventIds.filter(id => id !== event.id))
                                    setSelectedDistributionIds(selectedDistributionIds.filter(id => id !== d.id))
                                  }
                                }}/>
                              </div>
                              <div>
                                {event.name}
                              </div>
                            </div>
                          )}
                        </div>
                        : <div>No events</div>
                      }
                    </div>
                  }
                </div>
              )}


            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex flex-column">
        <FormCheck
          id="active-families-only-filter"
          style={{ width: "max-content" }}
          label="Active families only"
          onChange={activeOnlyChanged}
          checked={filters.active === "1"}
        />
        <FormCheck
          id="exclude-exceptions-filter"
          style={{ width: "max-content" }}
          label="Exclude Exceptions"
          checked={filters.exception === "[-1]"}
          onChange={excludeExceptionsChanged}
        />
      </div>
    </div>
  );
};

export default MoreFilters;
