import React, { useMemo, useContext, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { ArrowLeft, ArrowRight, XCircle } from "react-feather";
import {
  dropdownValuesKeyMap,
  UtilityContext,
} from "../../../contexts/UtilityContext";
import { DistributionContext } from "../../../contexts/DistributionContext";
import { CampaignContext } from "../../../contexts/CampaignContext";
import { booleanValues, lowerFirst } from "../../../utils";
import useKeyBy from "../../../hooks/useKeyBy";
import { FilterModes, FilterModeTitles, OR_FILTER } from "../../../constants";
import familyColumns from "../../tables/columns/family";
import "./filter-values-styles.css";

const FilterValueItem = ({
  filter,
  onFilterRemove,
  onFilterTypeChange,
  toAnd = false,
}) => (
  <Badge
    pill
    data-filter-key={filter.key}
    bg={filter.key !== "savedFilter" ? "light" : "primary"}
    text={filter.key !== "savedFilter" ? "dark" : "light"}
    className="me-1 my-1 filter-value-item"
  >
    <div className="d-flex flex-column align-items-center justify-content-center filter-value-item-content">
      <div>
        {filter.key !== "savedFilter" && `${filter.title} : `}
        <span className="fs-6 ms-1 me-1">{filter.value}</span>
      </div>
      <div className="change-filter-control">
        {filter.key !== "savedFilter" &&
        !(
          filter.key === "exception" &&
          toAnd &&
          filter.value.includes(", ")
        ) ? (
          <div
            className="px-2 py-1 border rounded-pill"
            onClick={() => onFilterTypeChange(filter.key)}
          >
            {toAnd && <ArrowLeft size={12} />}
            <span className="me-1">To {toAnd ? "AND" : "OR"} filters</span>
            {!toAnd && <ArrowRight size={12} />}
          </div>
        ) : (
          <span />
        )}
        <XCircle
          size={16}
          className="ms-1 hoverable"
          onClick={() => onFilterRemove(filter.key)}
        />
      </div>
    </div>
  </Badge>
);

/**
 * @typedef {import("../../tables/columns/family").TableColumnConfig} TableColumnConfig
 * @param {string} filterValue
 * @param {TableColumnConfig} column
 * @returns {string}
 */
function formatValue(filterValue, column, enums) {
  switch (column.type) {
    case "number":
    case "date": {
      const { value, mode, isEmpty, minValue, maxValue } = JSON.parse(
        filterValue || "{}"
      );
      if (+mode === FilterModes.Between) {
        return `${minValue}. . .${maxValue}`;
      }
      if (+mode === FilterModes.Contains) {
        return `Contains ${minValue}. . .${maxValue}`;
      }
      return isEmpty ? "EMPTY" : `${FilterModeTitles[mode]} ${value}`;
    }
    case "enum": {
      if (filterValue === "[-1]") {
        return "is empty";
      }
      if (Object.values(dropdownValuesKeyMap).includes(column.key)) {
        return enums?.[`${column.key}Map`]?.[JSON.parse(filterValue)[0]];
      }
      const valueArray = JSON.parse(filterValue || "[]");
      if (valueArray.length && enums?.[column.key]?.[valueArray[0]]) {
        return valueArray
          .map((item) => enums[column.key][item].displayNumber)
          .join(", ");
      }
      return valueArray.map((i) => i.value ?? i).join(", ");
    }
    case "boolean": {
      if (column.labels) {
        return column.labels[filterValue];
      }
      return booleanValues[filterValue];
    }
    default: {
      return filterValue === "-1" ? "EMPTY" : filterValue;
    }
  }
}

/**
 * @param {{
 *  filters: Record<string, string>;
 *  onChange: (filters: Record<string, string>) => void;
 * }} param0
 */
const FilterValues = ({ filters, onChange }) => {
  const { dropdownValues, exceptionOptions } = useContext(UtilityContext);
  const { distributions, loadDistributions } = useContext(DistributionContext);
  const { campaigns, loadCampaigns } = useContext(CampaignContext);
  const columns = useMemo(
    () =>
      familyColumns(dropdownValues, exceptionOptions).map((column) => {
        if (column.type === "enum" && !column.values?.length) {
          return {
            ...column,
            values: dropdownValues[column.key] || [],
          };
        }

        return column;
      }),
    [dropdownValues, exceptionOptions]
  );

  useEffect(() => {
    loadDistributions();
    loadCampaigns();
  }, [loadDistributions, loadCampaigns]);

  const distributionsMap = useKeyBy(distributions, "id");
  const campaignsMap = useKeyBy(campaigns, "id");
  const columnByKey = useKeyBy(columns, "key");

  const filterArrays = useMemo(() => {
    const newFilterArrays = {
      and: [],
      or: [],
    };

    Object.keys(filters).forEach((_key) => {
      const key = lowerFirst(_key);
      const filterValue = filters[_key];
      if (
        (!filterValue && filterValue !== false) ||
        [
          //"detailsPopup",
          "maxRows",
          "page",
          "filterId",
          "pageNumber",
          "sortOptions",
          "valueKind",
          "searchTerm",
          "excludedDistributionIds",
          "excludedEventIds"
        ].includes(key)
      ) {
        return;
      }
      if (key === "savedFilter") {
        newFilterArrays.and.push({
          key,
          value: filterValue,
        });
        return;
      }
      if (key === "distributionId") {
        if (distributionsMap[filterValue]) {
          newFilterArrays.and.push({
            key,
            value: distributionsMap[filterValue].name,
            title: "Distribution",
          });
        }
        return;
      }
      if (key === "distributionEventId") {
        newFilterArrays.and.push({
          key,
          value: filterValue,
          title: "Distribution Event",
        });
        return;
      }
      if (key === "campaignId") {
        if (campaignsMap[filterValue]) {
          newFilterArrays.and.push({
            key,
            value: campaignsMap[filterValue].keyword,
            title: "Campaign",
          });
        }
        return;
      }
      try {
        let jsonFilter;
        try {
          jsonFilter = JSON.parse(filterValue || "{}");
        } catch (_err) {}
        if (jsonFilter?.[0]?.type) {
          jsonFilter = {
            type: OR_FILTER,
            value: jsonFilter.map((i) => i.value),
          };
        }
        if (jsonFilter?.type === OR_FILTER) {
          try {
            jsonFilter.value = JSON.parse(jsonFilter.value);
          } catch (_err) {}
          const valueArray = Array.isArray(jsonFilter.value)
            ? jsonFilter.value
            : [jsonFilter.value];
          newFilterArrays.or.push({
            key,
            value: valueArray
              .map((item) => {
                if (Object.values(dropdownValuesKeyMap).includes(key)) {
                  return dropdownValues?.[`${key}Map`]?.[item];
                }
                return dropdownValues?.[key]?.[item]?.displayNumber || item;
              })
              .join(", "),
            title: columnByKey[key].title,
          });
          return;
        }
        newFilterArrays.and.push({
          key,
          value: formatValue(filterValue, columnByKey[key], dropdownValues),
          title: columnByKey[key].title,
        });
      } catch (err) {
        console.error(
          !columnByKey[key]
            ? `Column for key "${key}" was not found!\nNote: column keys are case sensitive.\n\nkey: ${key}; value: ${filterValue}`
            : err
        );
      }
    });
    return newFilterArrays;
    // eslint-disable-next-line
  }, [filters, dropdownValues, campaignsMap, distributionsMap, columnByKey]);

  const handleFilterRemove = (key) => {
    const newFilters = { ...filters };
    delete newFilters[key];
    if (key === "savedFilter") {
      onChange({});
      return;
    }
    onChange(newFilters);
  };

  const handleChangeFilterToOr = (key) => {
    const newFilters = { ...filters };

    newFilters[key] = JSON.stringify({
      type: OR_FILTER,
      value: newFilters[key],
    });

    onChange(newFilters);
  };

  const handleChangeFilterToAnd = (key) => {
    try {
      const newFilters = { ...filters };
      const filter = JSON.parse(newFilters[key]);

      newFilters[key] = filter.value;
      onChange(newFilters);
    } catch (_err) {}
  };

  if (!filterArrays.and.length && !filterArrays.or.length) {
    return null;
  }

  return (
    <div
      className="d-flex flex-wrap align-items-center"
      style={{ minHeight: 56, marginBottom: -8 }}
    >
      <div className="d-flex me-2 overflow-auto flex-wrap align-items-center">
        {!filterArrays.and.length ? (
          <div className="py-1 px-3 border">No AND filters</div>
        ) : (
          filterArrays.and.map((filter) => (
            <FilterValueItem
              key={filter.key}
              filter={filter}
              onFilterRemove={handleFilterRemove}
              onFilterTypeChange={handleChangeFilterToOr}
            />
          ))
        )}
      </div>
      <div className="py-2 h-100">
        <div className="border h-100 mx-1" />
      </div>
      <div className="d-flex ms-2 overflow-auto flex-wrap align-items-center">
        {!filterArrays.or.length ? (
          <div className="py-1 px-3 border">No OR filters</div>
        ) : (
          filterArrays.or.map((filter) => (
            <FilterValueItem
              key={filter.key}
              filter={filter}
              onFilterRemove={handleFilterRemove}
              onFilterTypeChange={handleChangeFilterToAnd}
              toAnd
            />
          ))
        )}
      </div>
    </div>
  );
};

export default FilterValues;
